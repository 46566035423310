<template>
  <div>
    <div class="l-console">
      <top-gradient />
      <div
        v-if="displayTopBar"
        class="flex flex-row justify-between h-22 bg-white border-b border-neutral-100"
      >
        <img
          class="my-auto ml-4 mr-7"
          src="/content/images/brightbid/brightbid-logo.png"
          alt="brightbid-logo"
        />
        <div
          class="flex border-l border-bb-pale-grey pl-6 pr-10 cursor-default h-full justify-end items-center relative"
          @mouseenter="dropDown = true"
          @mouseleave="dropDown = false"
        >
          <div class="my-auto flex flex-col">
            <p class="p2 font-medium">
              {{ auditUser.name }}
            </p>
            <p class="p3">{{ auditUser.email }}</p>
          </div>
          <div
            v-if="dropDown"
            :class="{ hover: dropDown }"
            @click.prevent="signOut"
            class="dropdown-content cursor-pointer"
          >
            <div class="h5">
              <div class="p-4 bg-neutral-100">
                <p class="p3 text-bb-mid-grey">Sign out</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main class="l-console__main">
        <div class="w-full">
          <transition
            name="page-fade"
            mode="out-in"
          >
            <router-view />
          </transition>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import TopGradient from '@/components/layout/TopGradient.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AuditLayout',
  components: {
    TopGradient,
  },
  data() {
    return {
      dropDown: false,
    }
  },
  methods: {
    ...mapActions({
      auditSignOut: 'auth/clearAuditSignIn',
    }),
    async signOut() {
      await this.$http.post(`audit/integration/google/ads/sign-out`)
      await this.auditSignOut()
      this.$router.push({ name: 'audit-signin' })
    },
  },
  computed: {
    displayTopBar() {
      if ((this.$route.name == 'audit-report' || this.$route.name == 'audit-report-history') && this.auditUser) {
        return true
      }
      return false
    },
    ...mapGetters({
      auditUser: 'auth/userAuditGoogleCredentials',
    }),
  },
}
</script>

<style lang="scss" scoped>
.l-console {
  max-width: 100%;
  width: 100%;
  position: absolute;
  overflow-x: hidden;

  &__main {
    @apply;
    @screen lg {
      &.sidebar-padding {
        margin-left: 224px;
      }
    }
  }
}
.col-account {
  @apply border-l border-bb-pale-grey;
}
.dropdown-content {
  @apply absolute right-0 bg-neutral-0;
  width: 218px;
  top: 88px;
  box-shadow: 0 0 0 1px rgba(201, 205, 214, 1);
}
</style>
