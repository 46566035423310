const state = {
  audits: [],
  auditDetails: {},
  auditSummary: {},
  auditsError: null,
  auditDetailsError: null,
  auditSummaryError: null,
}

const mutations = {
  SET_AUDITS(state, audits) {
    state.audits = audits
  },
  SET_AUDIT_DETAILS(state, audit) {
    state.auditDetails = audit
  },
  SET_AUDIT_SUMMARY(state, auditSummary) {
    state.auditSummary = auditSummary
  },
  SET_AUDITS_ERROR(state, error) {
    state.auditsError = error
  },
}

const actions = {
  async fetchAudits({ commit }, { siteId, status }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report`, {
        params: { status },
      })
      commit('SET_AUDITS_ERROR', null)
      response.data.forEach((audit, index) => {
        audit.auditNumber = response.data.length - index
      })
      commit('SET_AUDITS', response.data)
    } catch (error) {
      commit('SET_AUDITS_ERROR', error)
      commit('SET_AUDITS', [])
    }
  },

  async fetchAuditDetails({ commit }, { siteId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report/${auditReportId}`)
      const processedAudit = {
        ...response.data,
        checks: response.data.checks.map(check => {
          if (check.score === 100 || check.issues_count === 0) {
            return { ...check, severity: 'passed' }
          }
          return check
        }),
      }

      commit('SET_AUDIT_DETAILS', processedAudit)
    } catch (error) {
      commit('SET_AUDIT_DETAILS_ERROR', error)
      commit('SET_AUDIT_DETAILS', null)
    }
  },

  async fetchAuditSummary({ commit }, { siteId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/site/${siteId}/report/${auditReportId}/summary`)
      commit('SET_AUDIT_SUMMARY', response.data)
    } catch (error) {
      commit('SET_AUDIT_SUMMARY_ERROR', error)
      commit('SET_AUDIT_SUMMARY', null)
    }
  },

  // External audit functions using customerId
  // External audit functions using customerId
  // External audit functions using customerId
  async fetchExternalAudits({ commit }, { customerId, status }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report`, {
        params: { status },
      })
      commit('SET_AUDITS', response.data)
      response.data.forEach((audit, index) => {
        audit.auditNumber = response.data.length - index
      })
    } catch (error) {
      commit('SET_AUDITS_ERROR', error)
      commit('SET_AUDITS', [])
    }
  },

  async fetchExternalAuditDetails({ commit }, { customerId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report/${auditReportId}`)
      // Process checks to update severity to passed if score is 100 or issues_count is 0
      const processedAudit = {
        ...response.data,
        checks: response.data.checks.map(check => {
          if (check.score === 100 || check.issues_count === 0) {
            return { ...check, severity: 'passed' }
          }
          return check
        }),
      }

      commit('SET_AUDIT_DETAILS', processedAudit)
    } catch (error) {
      commit('SET_AUDIT_DETAILS_ERROR', error)
      commit('SET_AUDIT_DETAILS', null)
    }
  },

  async fetchExternalAuditSummary({ commit }, { customerId, auditReportId }) {
    try {
      const response = await axios.get(`/audit/customer/${customerId}/report/${auditReportId}/summary`)
      commit('SET_AUDIT_SUMMARY', response.data)
    } catch (error) {
      commit('SET_AUDIT_SUMMARY_ERROR', error)
      commit('SET_AUDIT_SUMMARY', null)
    }
  },
}

const getters = {
  audits: state => state.audits,

  latestCompletedAudit: state => {
    if (!state.audits.length) {
      return null
    }
    return state.audits
      .filter(audit => audit.status === 'completed')
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
  },
  latestCreatedAudit: state => {
    if (!state.audits.length) {
      return null
    }
    return state.audits.filter(audit => audit.id).sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0]
  },

  hasPendingAudit: state => {
    if (!state.audits.length) {
      return null
    }
    return state.audits.some(audit => audit.status === 'running')
  },

  getAuditDetails: state => state.auditDetails,
  getAuditSummary: state => state.auditSummary,
  getAuditsError: state => state.auditsError,
  getAuditDetailsError: state => state.auditDetailsError,
  getAuditSummaryError: state => state.auditSummaryError,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
